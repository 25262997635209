if ($(window).outerWidth(true) > 767) {
  $(".offer").each(function () {
    const id = $(this).data("id");
    const boxHeight =
      $(".offer[data-id=" + id + "] .offer__info").outerHeight(true) - 50;
    $(".offer[data-id=" + id + "] .offer__accent").css("height", boxHeight);
  });
}

if ($(window).outerWidth(true) > 991) {
  $(".hp__content").each(function () {
    $(".hp__content h3").css("min-height", $(".hp__content h3").height());
  });
}
