import scrollIt from "scrollit";

$.scrollIt({
  upKey: 38,
  downKey: 40,
  easing: "linear",
  scrollTime: 800,
  activeClass: "active",
  onPageChange: null,
  topOffset: -80,
  margin: 1,
});
