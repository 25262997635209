$(window).scroll(function () {
  sticky();
});

$(".hamburger").on("click", function () {
  if (!$(this).hasClass("hamburger--active")) {
    $(this).addClass("hamburger--active");
    if (!$(".header").hasClass("sticky")) {
      $(".header").addClass("sticky");
    }
    $("nav").slideDown(300);
  } else {
    $(this).removeClass("hamburger--active");
    sticky();
    $("nav").slideUp(300);
  }
});

function sticky() {
  if ($(window).scrollTop() > 7) {
    $(".header").addClass("sticky");
  } else if ($(window).scrollTop() <= 7) {
    $(".header").removeClass("sticky");
  }
  if ($(".hamburger").hasClass("hamburger--active")) {
    $(".header").addClass("sticky");
  }
}
