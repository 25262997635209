import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

const hero = $(".hero__slider");
hero.owlCarousel({
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  autoplay: true,
  autoplayTimeout: 4000,
  smartSpeed: 2000,
  touchDrag: false,
  mouseDrag: false,
});

const trust = $(".trust__slider");
trust.owlCarousel({
  loop: true,
  nav: false,
  dots: false,
  autoplay: true,
  slideTransition: "linear",
  smartSpeed: 2100,
  autoplayTimeout: 2000,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
      margin: 20,
    },
    1200: {
      items: 3,
      margin: 40,
    },
  },
});

const people = $(".people__slider");
people.owlCarousel({
  items: 4,
  loop: true,
  nav: false,
  dots: false,
  touchDrag: false,
  mouseDrag: false,
  margin: 30,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
  },
});
$("#people-prev").on("click", function () {
  people.trigger("prev.owl.carousel");
});
$("#people-next").on("click", function () {
  people.trigger("next.owl.carousel");
});

const opinions = $(".opinions__slider");
opinions.owlCarousel({
  items: 1,
  loop: true,
  nav: false,
  dots: false,
  autoplay: true,
  autoplayTimeout: 6000,
  smartSpeed: 1500,
  touchDrag: false,
  mouseDrag: false,
});
$("#opinions-prev").on("click", function () {
  opinions.trigger("prev.owl.carousel");
});
$("#opinions-next").on("click", function () {
  opinions.trigger("next.owl.carousel");
});

const logos = $(".logos__slider");
logos.owlCarousel({
  loop: true,
  nav: false,
  dots: false,
  touchDrag: false,
  mouseDrag: false,
  autoplay: true,
  slideTransition: "linear",
  smartSpeed: 2100,
  autoplayTimeout: 2000,
  margin: 5,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 5,
    },
  },
});

const realization = $(".single-realization__slider");
realization.owlCarousel({
  items: 3,
  loop: true,
  nav: false,
  dots: true,
  autoplay: true,
  autoplayTimeout: 4000,
  smartSpeed: 1500,
  margin: 40,
  autoWidth: true,
  touchDrag: false,
  mouseDrag: false,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 2,
    },
    992: {
      items: 3,
    },
  },
});
